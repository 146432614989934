import "lazysizes"

// スムーススクロール
$('a[href^="#"]').click(function () {
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  const position =
    target.offset().top -
    (window.matchMedia("(max-width: 835px)").matches ? 0 : 110);
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

//アコーディオン
$(".header__spmenu__accordion").each(function () {
  $(this).on("click", function () {
    $("+.header__spmenu__accordion__list", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});
$(".faq__item__q").each(function () {
  $(this).on("click", function () {
    $("+div", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});


//ヘッダーメニュー
$(".header__sp__btn, .header__spmenu__closebtn, .header__spmenu__item").on(
  "click",
  function () {
    $("html").toggleClass("scroll-prevent");
    if ($(".header__sp__btn").hasClass("is-active")) {
      $(".header__sp__btn").removeClass("is-active");
      $(".header__spmenu").removeClass("is-active");
      $(".header__spmenu__header").removeClass("is-active");
    } else {
      $(".header__sp__btn").addClass("is-active");
      $(".header__spmenu").addClass("is-active");
      $(".header__spmenu__header").addClass("is-active");
    }
  }
);

// スライダー
$(".case__slide").slick({
  slidesToShow: 3,
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "12%",
      },
    },
  ],
});
$(".voice__slide").slick({
  slidesToShow: 3,
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "12%",
      },
    },
  ],
});


$(window).scroll(function () {
  var scroll = $(window).scrollTop();

  if (scroll >= 50) {
    $(".header").addClass("is-active");
  } else {
    $(".header").removeClass("is-active");
  }
});

// カテゴリ名に応じた改行の挿入
$(".category").each(function () {
  var categoryText = $(this).text().trim();
  if (categoryText === "玄関ドア・スチールドア") {
    $(this).html("玄関ドア<br>・スチールドア");
  }
  if (categoryText === "エントランス（ドア・自動ドア）") {
    $(this).html("エントランス<br>（ドア・自動ドア）");
  }
  if (categoryText === "ベランダ手すり・エクステリア") {
    $(this).html("ベランダ手すり・<br>エクステリア");
  }  
});


$(".modal__video").modalVideo({
  autoplay: true,
});

// スクロールアニメーション
const scrollFunc = function () {
  const target = document.getElementsByClassName("scroll");
  const isSP = window.matchMedia("(max-width: 835px)").matches;
  const position = Math.floor(window.innerHeight * (isSP ? 0.7 : 0.9));
  for (let i = 0; i < target.length; i++) {
    let offsetTop = Math.floor(target[i]?.getBoundingClientRect().top);
    if (offsetTop < position) {
      target[i].classList.add("is-active");
    } else {
      target[i].classList.remove("is-active");
    }
  }
};
window.addEventListener("scroll", scrollFunc, false);

// 画像ポップアップ
$('.show_pop').on('click', function () {
  const thumbnail = $(this).data('thumbnail'); // サムネイルURLを取得

  // モーダルにサムネイル画像をセット
  $('.modal_thumbnail').attr('src', thumbnail);

  // モーダルを表示
  $('.modal_pop').fadeIn();
});

// モーダルを閉じる
$('.js-modal-close').on('click', function () {
  $('.modal_pop').fadeOut();
});


// プライバシー
$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(
      `<span></span><p><a class="wpcf7-list-item-label" href="${pathUrl}/privacy" target="_blank">` +
        $(this).text() +
        `<img src="${pathSrc}/img/common/external-link.svg" alt=""/></a>について同意する`
    );
  });
});